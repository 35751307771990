








































































































































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Sidebar from '@/components/left-sidebar.vue'; // @ is an alias to /src
import RSidebar from '@/components/right-sidebar.vue'; // @ is an alias to /src
import productService from '@/services/product-service';

@Component({
  components: {
    Header,
    Sidebar,
    RSidebar
  },
})
export default class ProductItem extends Vue {
  private data: any[] = [];
  private currentItem: any = null;
  private currentIndex: number = -1;
  private title: string = "";
  private rows = 10000;
  private currentPage = 1
  public obj:any ={}

  public fields = [
          {
            key: 'id',
            label: "#",
            sortable: true
          },
          {
            key: 'sku',
            sortable: false
          },
          {
            key: 'title',
            sortable: false
          },
         {
            key: 'title_arabic',
            sortable: false
          },
          {
            key: 'descripton',
            label: 'Description',
            sortable: false
          },
          {
            key: 'no_of_pieces',
            sortable: false
          },
          {
            key: 'category',
            sortable: false,
            
          },
          {
            key: 'sub_category_level_1',
            sortable: false,
            
          },
          {
            key: 'sub_category_level_2',
            sortable: false,
            
          }
        ];

public columns = [
          {
             label: 'sku',
            field: 'sku',
          },
          {
             label: 'title',
            field: 'title',
          },
         {
            label: 'title_arabic',
            field: 'title_arabic'
          },
          {         
            label: 'descripton',
            field: 'description',
          },
          {
            label: 'no_of_pieces',
            field: 'no_of_pieces',
          },
          {
         
            label: 'category',
            field: 'category',
            
          },
          {
            label: 'sub_category_level_1',
            field: 'sub_category_level_1',
            
          },
          {
            label: 'sub_category_level_2',
            field: 'sub_category_level_2',
            
          }
          ];
               
  public items:any = []
  public ddl_category:any = []
  public ddl_category_1:any = []
  public ddl_category_2:any = []

  retrieve() {
    productService.get_item()
      .then((response) => {
        this.items = response.data;
      })
      .catch((e) => {
        console.log(e);
      });
    productService.get_category('')
      .then((response) => {
        this.ddl_category = response.data;
      })
      .catch((e) => {
        console.log(e);
      });
       productService.get_sub_level_1_category('')
      .then((response) => {
        this.ddl_category_1 = response.data;
      })
      .catch((e) => {
        console.log(e);
      });
       productService.get_sub_level_2_category('')
      .then((response) => {
        this.ddl_category_2 = response.data;
      })
      .catch((e) => {
        console.log(e);
      });
      
  }

  refreshList() {
    this.retrieve();
    this.currentItem = null;
    this.currentIndex = -1;
  }

  create(){
   let data = this.obj;
    productService.create_item(data)
      .then((response) => {
        this.items = response.data;
        this.obj = {};        
        this.retrieve()
      })
      .catch((e) => {
        console.log(e);
      });
  }
  mounted() {
    this.retrieve();
  }
}
